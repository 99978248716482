<template>
  <div v-loading="loading">
    <el-steps
      :active="activestep"
      finish-status="success"
      process-status="process"
      simple
      style="margin-top: 20px"
    >
      <el-step title="1.基本信息"></el-step>
      <el-step title="2.票种信息"></el-step>
      <el-step title="3.活动详情"></el-step>
    </el-steps>
    <!--步骤1-->
    <div class="content-goods-publish" v-show="activestep === 0">
      <el-form
        :model="baseInfoForm"
        status-icon
        label-position="right"
        ref="baseInfoForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <!--基本信息-->
        <div class="base-info-item">
          <h4>基本信息</h4>
          <div>
            <el-form-item label="活动名称：">
              <el-input
                v-model="baseInfoForm.activity_name"
                class="ipt-default width"
                placeholder="请输入活动名称"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动分类：">
              <el-cascader
                expand-trigger="hover"
                :options="activityTypeList"
                v-model="activityTypeChoose"
                :disabled="true"
                style="width: 200px"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="活动时长：">
              <div style="display: flex">
                <el-radio
                  v-model="activityTime.activityTimeType"
                  :disabled="true"
                  label="1"
                >
                  <el-input
                    v-model="activityTime.activityTimeDay"
                    class="ipt-default"
                    placeholder="未输入天数"
                    style="width: 125px"
                    :disabled="true"
                  ></el-input
                  >天
                </el-radio>
                <el-radio
                  v-model="activityTime.activityTimeType"
                  :disabled="true"
                  label="2"
                >
                  <el-input
                    v-model="activityTime.activityTimeHour"
                    class="ipt-default"
                    placeholder="未输入小时数"
                    style="width: 125px"
                    :disabled="true"
                  ></el-input
                  >小时
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="孩子年龄：">
              <div style="display: flex; align-items: center">
                <el-radio
                  v-model="baseInfoForm.child_limit_sign"
                  :label="2"
                  style="display: flex; align-items: center"
                  :disabled="true"
                >
                  <div style="display: flex; align-items: center">
                    <el-input
                      v-model="baseInfoForm.child_limit_gte"
                      class="ipt-default"
                      placeholder="下限"
                      style="width: 70px"
                      :disabled="true"
                    ></el-input>
                    <span style="color: #aaa; margin-right: 10px">至</span>
                    <el-input
                      v-model="baseInfoForm.child_limit_lt"
                      class="ipt-default"
                      placeholder="上限"
                      style="width: 70px"
                      :disabled="true"
                    ></el-input>
                    <span style="color: #222">岁</span>
                    <span style="color: #aaa">(6-18周岁)</span>
                  </div>
                </el-radio>
                <el-radio
                  v-model="baseInfoForm.child_limit_sign"
                  :disabled="true"
                  :label="1"
                  >不限</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item label="参与方式：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.partake"
                  :disabled="true"
                  :label="1"
                  >孩子独立参加</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.partake"
                  :disabled="true"
                  :label="2"
                  >父母随行</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.partake"
                  :disabled="true"
                  :label="3"
                  >不限</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item label="地址类型：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.addr_type"
                  :disabled="true"
                  :label="1"
                  >集合地</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.addr_type"
                  :disabled="true"
                  :label="2"
                  >活动地点</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.addr_type"
                  :disabled="true"
                  :label="3"
                  >商铺地址</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item label="详细地址：">
              <div class="addressBox">
                <div>
                  <el-select
                    v-model="activityAddress"
                    style="width: 300px; margin-right: 10px"
                    filterable
                    clearable
                    placeholder="请选择"
                    :disabled="true"
                  >
                    <el-option
                      v-for="item in activityAddressList"
                      :key="item.id"
                      :label="item.province + item.city + item.country"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <el-input
                  style="width: 550px; margin-top: 10px; color: #333"
                  type="textarea"
                  :rows="2"
                  placeholder="请选择地址"
                  :value="addressDetails"
                  resize="none"
                  :disabled="true"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="客服电话：">
              <div style="display: flex; width: 610px">
                <el-input
                  v-model="baseInfoForm.service_phone1"
                  class="ipt-default"
                  style="width: 300px; margin-right: 20px"
                  placeholder="未填写"
                  :disabled="true"
                ></el-input>
                <el-input
                  v-model="baseInfoForm.service_phone2"
                  class="ipt-default"
                  style="width: 300px"
                  placeholder="未填写"
                  :disabled="true"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="营业时间显示：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.worktime_sign"
                  :disabled="true"
                  :label="1"
                  >是</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.worktime_sign"
                  :disabled="true"
                  :label="0"
                  >否</el-radio
                >
                <span style="color: #888; font-size: 14px"
                  >* 若显示，请先在店铺管理中维护营业时间</span
                >
              </div>
            </el-form-item>
            <el-form-item label="活动标签：">
              <div style="width: 600px; display: flex">
                <div
                  style="
                    border: 1px solid rgba(221, 221, 221, 1);
                    border-radius: 8px;
                    padding: 5px;
                    width: 550px;
                    margin-right: 10px;
                  "
                >
                  <el-tag
                    style="background: #008080; color: #fff; margin-right: 10px"
                    :key="index"
                    v-for="(item, index) in activityTagChooseList"
                    >{{ item.label_name }}</el-tag
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="能力标签：">
              <div style="width: 600px; display: flex">
                <div
                  style="
                    border: 1px solid rgba(221, 221, 221, 1);
                    border-radius: 8px;
                    padding: 5px;
                    width: 550px;
                    margin-right: 10px;
                  "
                >
                  <el-tag
                    style="background: #008080; color: #fff; margin-right: 10px"
                    :key="index"
                    v-for="(item, index) in abilityTagChooseList"
                    >{{ item.label_name }}</el-tag
                  >
                </div>
              </div>
            </el-form-item>
            <el-form-item label="使用优惠券：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.coupon_sign"
                  :disabled="true"
                  :label="1"
                  >是</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.coupon_sign"
                  :disabled="true"
                  :label="0"
                  >否</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item label="填写出行人：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.traveler_sign"
                  :disabled="true"
                  :label="1"
                  >是</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.traveler_sign"
                  :disabled="true"
                  :label="0"
                  >否</el-radio
                >
              </div>
              <div
                class="travelerTagBox"
                v-if="baseInfoForm.traveler_sign === 1"
              >
                <el-form-item label="出行人信息：">
                  <div class="tagBox">
                    <el-tag
                      class="tagNo"
                      :class="travelerList[0] === 1 ? 'tagYes' : ''"
                      >出行人姓名</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[1] === 1 ? 'tagYes' : ''"
                      >出行人英文姓</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[2] === 1 ? 'tagYes' : ''"
                      >出行人英文名</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[3] === 1 ? 'tagYes' : ''"
                      >出行人证件类型</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[4] === 1 ? 'tagYes' : ''"
                      >出行人证件号</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[5] === 1 ? 'tagYes' : ''"
                      >出行人证件有效期</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[6] === 1 ? 'tagYes' : ''"
                      >出行人国籍</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[7] === 1 ? 'tagYes' : ''"
                      >出行人手机号</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[8] === 1 ? 'tagYes' : ''"
                      >生日</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[9] === 1 ? 'tagYes' : ''"
                      >性别</el-tag
                    >
                    <el-tag
                      class="tagNo"
                      :class="travelerList[10] === 1 ? 'tagYes' : ''"
                      >出行人类型</el-tag
                    >
                  </div>
                </el-form-item>
                <!-- <el-form-item label="出行人类型：">
                  <div class="tagBox">
                    <el-tag class="tagNo tagYes" @click="traveler(8)">成人</el-tag>
                    <el-tag class="tagNo" @click="traveler(9)">儿童</el-tag>
                  </div>
                </el-form-item>-->
              </div>
            </el-form-item>
            <el-form-item label="填写紧急联系人：" class="urgentBox">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.emergency_contact_sign"
                  :disabled="true"
                  :label="1"
                  >是</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.emergency_contact_sign"
                  :disabled="true"
                  :label="0"
                  >否</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item label="活动图片：">
              <div class="activityImgBox">
                <div
                  v-for="(item, index) in baseInfoForm.goods_gallery_dos"
                  :key="index"
                  class="imgBox"
                >
                  <div class="activityUploadBox" v-if="item.original === ''">
                    <div>未上传图片</div>
                  </div>
                  <div v-if="item.original != ''">
                    <img :src="item.original" class="photo" />
                  </div>
                </div>
                <!-- <div>
                  <div class="explain">* 第1张图是产品主图，必传(用于产品详情、产品列表页自动缩小比例)</div>
                  <div class="explain">* 第2-5张图是产品附属图，选传</div>
                  <div class="explain">* 第6张图是首页精选区图片主图，必传</div>
                  <div class="explain">* 第7-9张图是首页精选区图片附属图，选传</div>
                  <div class="explain">* 第10张图是首页各分类主推图，必传</div>
                </div> -->
              </div>
            </el-form-item>
            <el-form-item label="活动视频：">
              <div class="activityVideoBox">
                <div class="activityUploadBox" v-if="baseInfoForm.video === ''">
                  <div>未上传视频</div>
                </div>
                <div
                  class="activityUploadVideoBox"
                  v-if="baseInfoForm.video !== ''"
                >
                  <video :src="baseInfoForm.video"></video>
                </div>
                <div class="explain">
                  * 用于显示在活动详情页，视频不大于100M
                </div>
              </div>
            </el-form-item>
            <el-form-item label="导师信息：">
              <el-select
                v-model="baseInfoForm.teacher_ids"
                style="width: 150px"
                placeholder="请选择导师"
                :disabled="true"
                multiple
              >
                <el-option
                  v-for="item in activityTutorList"
                  :key="item.id"
                  :label="item.teacher_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!--步骤2-->
    <div class="content-goods-publish" v-show="activestep === 1">
      <el-form
        :model="baseInfoForm"
        status-icon
        label-position="right"
        ref="baseInfoForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <!--票种信息-->
        <div class="base-info-item">
          <h4>票种信息</h4>
          <div>
            <el-form-item label="票种数量：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.sale_limit_sign"
                  :disabled="true"
                  :label="1"
                  >不限</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.sale_limit_sign"
                  :disabled="true"
                  :label="2"
                >
                  限制总预定数
                  <el-input
                    v-model="baseInfoForm.sale_limit_num"
                    class="ipt-default"
                    style="width: 100px"
                    :disabled="true"
                  ></el-input>
                </el-radio>
                <el-radio
                  v-model="baseInfoForm.sale_limit_sign"
                  :disabled="true"
                  label="3"
                >
                  按票种限制每场预订数
                  <span style="font-size: 12px; color: #999; margin-left: 7px"
                    >如话剧、演出</span
                  >
                </el-radio>
              </div>
            </el-form-item>
            <el-form-item label="规格票种设置：">
              <div
                class="ticketTypeBox"
                v-for="(ticketTypeItem,
                ticketTypeIndex) in baseInfoForm.ticket_query_vos"
                :key="ticketTypeIndex"
              >
                <div class="ticketTypeTopBox">
                  <div class="ticketTypeTopTitleBox">
                    <div class="left">
                      <div></div>
                      <span>票种{{ ticketTypeIndex + 1 }}</span>
                    </div>
                  </div>
                  <el-input
                    v-model="ticketTypeItem.ticket_name"
                    class="ticketTypeIpt"
                    placeholder="添加票种名称"
                    :disabled="true"
                  ></el-input>
                  <el-input
                    v-model="ticketTypeItem.ticket_info"
                    class="ticketTypeIpt"
                    placeholder="未添加票种描述"
                    :disabled="true"
                  ></el-input>
                  <el-input
                    v-model="ticketTypeItem.fee_info"
                    class="ticketTypeIpt"
                    placeholder="添加费用描述(必填)"
                    :disabled="true"
                  ></el-input>
                  <div class="info">
                    <div>
                      <div class="name">原价</div>
                      <div class="inpBox">
                        <el-input
                          v-model="ticketTypeItem.mktprice"
                          class="inp"
                          :disabled="true"
                        ></el-input>
                        <div>元</div>
                      </div>
                    </div>
                    <div>
                      <div class="name">销售价</div>
                      <div class="inpBox">
                        <el-input
                          v-model="ticketTypeItem.price"
                          class="inp"
                          :disabled="true"
                        ></el-input>
                        <div>元</div>
                      </div>
                    </div>
                    <!-- <div>
                      <div class="name">单场可售数</div>
                      <div class="inpBox">
                        <el-input v-model="ticketTypeItem.single_sale_num" class="inp"></el-input>
                        <div>份</div>
                      </div>
                    </div>-->
                    <div>
                      <div class="name">用户限购</div>
                      <div class="selectBox">
                        <el-select
                          v-model="ticketTypeItem.limit_sign"
                          class="select"
                          filterable
                          placeholder="请选择"
                          :disabled="true"
                        >
                          <el-option
                            v-for="item in quotaTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div>
                      <div class="name">期次类型</div>
                      <div class="selectBox">
                        <el-select
                          v-model="ticketTypeItem.multi_period"
                          class="select"
                          filterable
                          placeholder="请选择"
                          :disabled="true"
                        >
                          <el-option
                            v-for="item in periodTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="ticketTypeBottomBox"
                  v-if="ticketTypeItem.multi_period === 1"
                >
                  <div
                    class="ticketTypeBottom"
                    :class="ticketPeriodIndex === 0 ? 'noBorder' : ''"
                    v-for="(ticketPeriodItem,
                    ticketPeriodIndex) in ticketTypeItem.goods_ticket_date_vos"
                    :key="ticketPeriodIndex"
                  >
                    <div class="titleBox">
                      <img src="/img/hdqc@2x.png" />
                      <div>第{{ ticketPeriodItem.ticket_num }}期</div>
                    </div>
                    <div class="dateAndTimeBox">
                      <el-form-item label="活动日期：" class="formItem">
                        <el-date-picker
                          style="width: 300px"
                          v-model="
                            ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                              ticketPeriodIndex
                            ].dates
                          "
                          type="daterange"
                          align="center"
                          :editable="false"
                          size="small"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          :picker-options="{
                            disabledDate(time) {
                              return time.getTime() < Date.now();
                            },
                          }"
                          :disabled="true"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                    <div
                      class="dateAndTimeBox"
                      v-for="(ticketTimeItem,
                      ticketTimeIndex) in ticketPeriodItem.goods_ticket_datetimes"
                      :key="ticketTimeIndex"
                    >
                      <el-form-item
                        :label="ticketTimeIndex === 0 ? '活动时间：' : ' '"
                        class="formItem"
                      >
                        <div class="timeBox">
                          <el-select
                            v-model="
                              ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                                ticketPeriodIndex
                              ].ticketPeriodTimes[ticketTimeIndex].ticketHourLow
                            "
                            class="select"
                            filterable
                            :disabled="true"
                          >
                            <el-option
                              v-for="item in ticketHourList"
                              :key="item"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                          <div class="colon">:</div>
                          <el-select
                            v-model="
                              ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                                ticketPeriodIndex
                              ].ticketPeriodTimes[ticketTimeIndex].ticketMinLow
                            "
                            class="select"
                            filterable
                            :disabled="true"
                          >
                            <el-option
                              v-for="item in ticketMinList"
                              :key="item"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                          <span class="to">至</span>
                          <el-select
                            v-model="
                              ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                                ticketPeriodIndex
                              ].ticketPeriodTimes[ticketTimeIndex]
                                .ticketHourHigh
                            "
                            class="select"
                            filterable
                            :disabled="true"
                          >
                            <el-option
                              v-for="item in ticketHourList"
                              :key="item"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                          <div class="colon">:</div>
                          <el-select
                            v-model="
                              ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                                ticketPeriodIndex
                              ].ticketPeriodTimes[ticketTimeIndex].ticketMinHigh
                            "
                            class="select"
                            filterable
                            :disabled="true"
                          >
                            <el-option
                              v-for="item in ticketMinList"
                              :key="item"
                              :label="item"
                              :value="item"
                            ></el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                    </div>
                    <div
                      class="dateAndTimeBox"
                      v-if="baseInfoForm.sale_limit_sign === 3"
                    >
                      <el-form-item label="本期可售数：" class="formItem">
                        <div style="width: 150px; display: flex">
                          <el-input
                            v-model="
                              ticketTypeList[ticketTypeIndex].ticketPeriodDates[
                                ticketPeriodIndex
                              ].sale_num
                            "
                            class="inp"
                            :disabled="true"
                          ></el-input>
                          <span style="margin-left: 10px; color: #999">份</span>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div
                  class="ticketTypeBottomBox"
                  v-if="ticketTypeItem.multi_period === 0"
                >
                  <div class="ticketTypeBottom noBorder">
                    <div class="dateAndTimeBox" style="margin-top: 0">
                      <el-form-item label="活动日期：" class="formItem">
                        <el-date-picker
                          style="width: 300px"
                          v-model="
                            ticketTypeList[ticketTypeIndex].ticketPeriodDates[0]
                              .dates
                          "
                          type="daterange"
                          align="center"
                          :editable="false"
                          size="small"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="timestamp"
                          :picker-options="{
                            disabledDate(time) {
                              return time.getTime() < Date.now();
                            },
                          }"
                          :disabled="true"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                    <div class="dayBox">
                      <el-form-item label="可选日期：" class="formItem">
                        <div class="tagBox">
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[0]
                                ? 'act'
                                : ''
                            "
                            >周一</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[1]
                                ? 'act'
                                : ''
                            "
                            >周二</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[2]
                                ? 'act'
                                : ''
                            "
                            >周三</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[3]
                                ? 'act'
                                : ''
                            "
                            >周四</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[4]
                                ? 'act'
                                : ''
                            "
                            >周五</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[5]
                                ? 'act'
                                : ''
                            "
                            >周六</el-tag
                          >
                          <el-tag
                            class="tag"
                            :class="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].dayList[6]
                                ? 'act'
                                : ''
                            "
                            >周日</el-tag
                          >
                        </div>
                      </el-form-item>
                    </div>
                    <div
                      class="dateAndTimeBox"
                      v-if="baseInfoForm.sale_limit_sign === 3"
                    >
                      <el-form-item label="本期可售数：" class="formItem">
                        <div style="width: 150px; display: flex">
                          <el-input
                            v-model="
                              ticketTypeList[ticketTypeIndex]
                                .ticketPeriodDates[0].count
                            "
                            class="inp"
                            :disabled="true"
                          ></el-input>
                          <span style="margin-left: 10px; color: #999">份</span>
                        </div>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="预定截止时间:">
              <div style="width: 600px" class="endTimeBox">
                <!-- <el-radio
                  v-model="endTimeType"
                  :label="1"
                  class="endTimeRadio"
                  :disabled="true"
                  >结束前可购买</el-radio
                >
                <el-radio
                  v-model="endTimeType"
                  :label="2"
                  class="endTimeRadio"
                  :disabled="true"
                > -->
                  <div class="endTimeBox1">
                    <span>活动开始前</span>
                    <div class="inpBox">
                      <el-radio v-model="defaultChecked" label="1">
                        <el-input
                          v-model="endTimes.day"
                          class="endTimeIpt"
                          @change="endTimesChange"
                          :disabled="isUp ? true : false"
                        ></el-input>
                      </el-radio>
                      <div style="padding: 0 5px">天</div>
                      <el-radio v-model="defaultChecked" label="2">
                        <el-input
                          v-model="endTimes.hour"
                          class="endTimeIpt"
                          @change="endTimesChange"
                          :disabled="isUp ? true : false"
                        ></el-input>
                      </el-radio>
                      <div style="padding: 0 5px">时</div>
                    </div>
                    <!-- <el-select
                      v-model="endTime"
                      class="select"
                      filterable
                      placeholder="请选择"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in endTimeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select> -->
                    <!-- <div class="inpBox">
                      <el-input
                        v-model="endTimes.day"
                        class="endTimeIpt"
                        :disabled="true"
                      ></el-input>
                      <div style="padding: 0 5px">天</div>
                      <el-input
                        v-model="endTimes.hour"
                        class="endTimeIpt"
                        :disabled="true"
                      ></el-input>
                      <div style="padding: 0 5px">时</div>
                    </div> -->
                  </div>
                <!-- </el-radio>
                <el-radio
                  v-model="endTimeType"
                  :label="4"
                  class="endTimeRadio"
                  :disabled="true"
                >
                  <el-date-picker
                    v-model="buy_limit_datetime"
                    type="datetime"
                    placeholder="未选择日期时间"
                    value-format="timestamp"
                    :picker-options="{
                      disabledDate(time) {
                        return time.getTime() < Date.now();
                      },
                    }"
                    :disabled="true"
                  ></el-date-picker>
                </el-radio> -->
              </div>
            </el-form-item>
          </div>
          <h4>销售/名额显示</h4>
          <div>
            <el-form-item label="初始已售数量：">
              <el-input
                v-model="baseInfoForm.init_sale_num"
                class="ipt-default"
                style="width: 100px"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="页面显示方式：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.show_type"
                  :disabled="true"
                  :label="1"
                  >已售</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.show_type"
                  :disabled="true"
                  :label="2"
                  >剩余</el-radio
                >
              </div>
            </el-form-item>
          </div>
          <h4>活动退款设置</h4>
          <div>
            <el-form-item label="是否支持退款：">
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.refund_type"
                  :disabled="true"
                  :label="0"
                  >不支持</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.refund_type"
                  :disabled="true"
                  :label="1"
                  >支持</el-radio
                >
              </div>
            </el-form-item>
            <el-form-item
              label="退款金额设置："
              v-if="baseInfoForm.refund_type === 1"
            >
              <div style="width: 600px">
                <el-radio
                  v-model="baseInfoForm.refund_all_sign"
                  :disabled="true"
                  :label="1"
                  >活动开始前退款全部</el-radio
                >
                <el-radio
                  v-model="baseInfoForm.refund_all_sign"
                  :disabled="true"
                  :label="2"
                  >自定义条件</el-radio
                >
              </div>
              <div
                class="refundPriceBox"
                v-if="baseInfoForm.refund_all_sign === 2"
              >
                <div
                  class="box"
                  v-for="(item, index) in baseInfoForm.diys"
                  :key="index"
                >
                  <div class="left">
                    <div class="text" style="width: 190px">
                      距离活动开始天数，如果满足
                    </div>
                    <el-input
                      v-model="item.time_gte"
                      class="soldCountInp"
                      style="width: 100px"
                      :disabled="true"
                    ></el-input>
                    <div class="text" style="width: 20px">至</div>
                    <el-input
                      v-model="item.time_lte"
                      class="soldCountInp"
                      style="width: 100px"
                      :disabled="true"
                    ></el-input>
                    <div class="text" style="width: 60px">天，退款</div>
                    <el-input
                      v-model="item.refund_point"
                      class="soldCountInp"
                      style="width: 100px"
                      :disabled="true"
                    ></el-input>
                    <div class="text" style="width: 16px">%</div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!--步骤3-->
    <div class="content-goods-publish" v-if="activestep === 2">
      <el-form
        :model="baseInfoForm"
        status-icon
        label-position="right"
        ref="baseInfoForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <!--活动详情-->
        <div class="base-info-item">
          <h4>活动详情</h4>
          <div>
            <el-form-item label="活动内容：">
              <div class="activityDetails">
                <div class="activityDetails pr-5">
                  <sig-editor-lite v-model="baseInfoForm.info"></sig-editor-lite>
                </div>
              </div>
            </el-form-item>
          </div>
          <h4>活动亮点</h4>
          <div>
            <el-form-item label="活动亮点：">
              <div
                class="characteristicBox"
                v-for="(item, index) in baseInfoForm.lights"
                :key="index"
              >
                <el-input
                  v-model="item.light_intro"
                  class="ipt-default"
                  placeholder="请输入活动亮点"
                  :disabled="true"
                ></el-input>
              </div>
            </el-form-item>
          </div>
          <h4>活动行程</h4>
          <div>
            <el-form-item label="活动行程：">
              <div
                class="tripBox"
                v-for="(item, index) in baseInfoForm.trips"
                :key="index"
              >
                <div class="tripContentBox">
                  <div class="left">{{ index + 1 }}</div>
                  <div class="right">
                    <el-input
                      v-model="item.trip_title"
                      class="inp"
                      placeholder="请输入活动行程标题"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      v-model="item.trip_intro"
                      class="inp"
                      style="margin-top: 10px"
                      placeholder="请输入活动行程内容"
                      :disabled="true"
                    ></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <h4>参加须知</h4>
          <div>
            <el-form-item label="参加须知：">
              <div
                class="tripBox"
                v-for="(item, index) in baseInfoForm.notices"
                :key="index"
              >
                <div class="tripContentBox">
                  <div class="right">
                    <el-input
                      v-model="item.notice_title"
                      class="inp"
                      :disabled="true"
                    ></el-input>
                    <el-input
                      type="textarea"
                      v-model="item.notice_intro"
                      class="inp"
                      style="margin-top: 10px; width: 538px; color: #606266"
                      autosize
                      resize="none"
                      :disabled="true"
                    ></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <!--底部-->
    <div class="footer">
      <el-button-group>
        <el-button
          type="primary"
          @click="pre"
          v-if="activestep === 1 || activestep === 2"
          >上一步</el-button
        >
        <el-button
          type="primary"
          @click="next"
          v-if="activestep === 0 || activestep === 1"
          >下一步</el-button
        >
      </el-button-group>
    </div>
  </div>
</template>

<script>
import * as API_activityAdd from "@/api/activityAdd";
import SigEditorLite from "@/components-new/sig-editor-lite/sig-editor-lite.vue";

export default {
  components: {
    SigEditorLite,
  },
  data() {
    return {
      /**  店铺信息 */
      shopInfo: this.$store.getters.shopInfo,

      /**  加载中。。。 */
      loading: false,

      /**  当前状态/模式 默认发布商品0 编辑商品1 编辑草稿箱商品2 */
      currentStatus: 1,

      /**  当前激活步骤 */
      activestep: 0,

      /**  基本信息提交表单 */
      baseInfoForm: {
        activity_name: "",

        category_first: "",
        category_id: "",
        category_second: "",
        category_id2: "",

        activity_duration: "",

        child_limit_sign: "",
        child_limit_gte: "",
        child_limit_lt: "",

        partake: "",

        addr_type: "",

        province_id: "",
        city_id: "",
        country_id: "",
        addr_detail: "",

        service_phone1: "",
        service_phone2: "",

        worktime_sign: "",

        label: "",

        label2: "",

        coupon_sign: "",

        traveler_sign: "",
        traveler_write_limit: "",

        emergency_contact_sign: "",

        goods_gallery_dos: [
          { sort: 1, original: "" },
          { sort: 2, original: "" },
          { sort: 3, original: "" },
          { sort: 4, original: "" },
          { sort: 5, original: "" },
          { sort: 6, original: "" },
          { sort: 7, original: "" },
          { sort: 8, original: "" },
          { sort: 9, original: "" },
          { sort: 10, original: "" },
        ],

        video: "",

        teacher_ids: "1",

        sale_limit_sign: "",
        quantity: "",
        ticket_query_vos: [
          {
            ticket_name: "",
            ticket_info: "",
            fee_info: "",
            mktprice: "",
            price: "",
            limit_sign: "",
            multi_period: 0,
            goods_ticket_date_vos: [
              {
                ticket_num: 0,
                start_date: "",
                end_date: "",
                goods_ticket_datetimes: [
                  {
                    time_start: "00:00",
                    time_end: "00:00",
                    tpeople_num: "",
                    tpeople_use_num: "",
                  },
                ],
                ticket_weekly: "",
              },
            ],
          },
        ],
        mkt_low: "",
        mkt_high: "",
        price_low: "",
        price_top: "",
        buy_limit_sign: "",
        buy_limit_hour: "",
        buy_limit_datetime: "",
        init_sale_num: "",
        show_type: "",
        refund_type: "",
        refund_all_sign: "",
        diys: [
          {
            refund_point: "",
            time_gte: "",
            time_lte: "",
          },
        ],

        info: "",
        lights: [
          {
            light_intro: "",
          },
        ],
        trips: [
          {
            trip_title: "",
            trip_intro: "",
          },
        ],
        notices: [
          {
            notice_title: "退款规则",
            notice_intro: "",
          },
          {
            notice_title: "",
            notice_intro: "",
          },
        ],
      },

      /**  活动分类列表 */
      activityTypeList: [],

      /** 活动时长 */
      activityTime: {
        activityTimeType: "",
        activityTimeDay: "",
        activityTimeHour: "",
      },

      /**  选中活动地址信息 */
      addressDetails: "",

      /**  地址列表 */
      activityAddressList: [],

      /**  选中活动分类 */
      activityTypeChoose: [0, 1],

      // 选中的地区id
      activityAddress: "",

      /**  活动标签列表 */
      activityTagChooseList: [],

      /** 能力标签列表 */
      abilityTagChooseList: [],

      /** 出行人信息列表 */
      travelerList: [0, 0, 0, 0, 0, 0, 0, 0, 0],

      /** 导师列表 */
      activityTutorList: [],

      /** 用户限购方式 */
      quotaTypeList: [
        { id: 0, name: "不限购" },
        { id: 1, name: "限购" },
      ],

      /** 期次类型 */
      periodTypeList: [
        { id: 0, name: "不多期" },
        { id: 1, name: "多期" },
      ],

      /** 活动日期和时间列表 */
      ticketTypeList: [
        {
          ticketPeriodDates: [
            {
              dates: [],
              ticketPeriodTimes: [
                {
                  ticketHourLow: "00",
                  ticketMinLow: "00",
                  ticketHourHigh: "00",
                  ticketMinHigh: "00",
                },
              ],
              dayList: [false, false, false, false, false, false, false],
              count: "",
            },
          ],
        },
      ],

      /** 活动时间小时列表 */
      ticketHourList: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],

      /** 活动时间分钟列表 */
      ticketMinList: ["00", "15", "30", "45"],

      /** 预定截止时间分类 */
      endTimeList: [
        { id: "2", name: "活动开始前" },
        { id: "3", name: "活动结束前" },
      ],

      /** 预定截止时间选中分类 */
      endTimeType: "",
      endTime: "",

      /** 预定截止时间的天数和小时数 */
      endTimes: {
        day: "",
        hour: "",
      },

      /** 预定截止时间的日期 */
      buy_limit_datetime: "",
    };
  },
  mounted() {
    console.log(this.$route.params);
    this.GET_activeGoodsActivityDetails();
  },
  activated() {
    this.GET_activeGoodsActivityDetails();
  },
  methods: {
    /**  上一步 */
    pre() {
      this.loading = false;
      if (this.activestep > 0) {
        this.activestep--;
      }
    },

    /**  下一步 */
    next() {
      this.loading = false;
      if (this.activestep++ > 2) return;
      console.log(this.baseInfoForm);
    },

    /**  活动详情回显 */
    GET_activeGoodsActivityDetails() {
      // console.log(res);
      this.baseInfoForm = this.$route.params;
      // 回显活动分类
      var activityTypeJson = {
        value: 0,
        label: this.baseInfoForm.category_first,
        children: [
          {
            value: 1,
            label: this.baseInfoForm.category_second,
          },
        ],
      };
      this.activityTypeList.push(activityTypeJson);

      // 回显活动时长
      //   if (Number(this.baseInfoForm.activity_duration) % 24 === 0) {
      //     this.activityTime.activityTimeType = "1";
      //     this.activityTime.activityTimeDay =
      //       Number(this.baseInfoForm.activity_duration) / 24;
      //   } else {
      //     this.activityTime.activityTimeType = "2";
      //     this.activityTime.activityTimeHour = this.baseInfoForm.activity_duration;
      //   }
      if (this.baseInfoForm.duration_day) {
        this.activityTime.activityTimeType = "1";
        this.activityTime.activityTimeDay = this.baseInfoForm.duration_day;
      } else {
        this.activityTime.activityTimeType = "2";
        this.activityTime.activityTimeHour = this.baseInfoForm.duration_hour;
      }

      // 回显活动地址
      this.activityAddress = this.baseInfoForm.province + this.baseInfoForm.city
      this.addressDetails = this.baseInfoForm.addr_detail;

      // 回显活动标签
      this.activityTagChooseList = this.baseInfoForm.label3;
      this.baseInfoForm.label = "";
      this.activityTagChooseList.forEach((item, index) => {
        if (index === 0) {
          this.baseInfoForm.label += item.id;
        } else {
          this.baseInfoForm.label += "," + item.id;
        }
      });

      // 回显能力标签
      this.abilityTagChooseList = this.baseInfoForm.label4;
      this.baseInfoForm.label2 = "";
      this.abilityTagChooseList.forEach((item, index) => {
        if (index === 0) {
          this.baseInfoForm.label2 += item.id;
        } else {
          this.baseInfoForm.label2 += "," + item.id;
        }
      });

      // 回显出行人信息
      this.travelerList = [];
      this.baseInfoForm.traveler_write_limit.split("").forEach((item) => {
        this.travelerList.push(Number(item));
      });

      // 回显预定截止时间
      if (this.baseInfoForm.buy_limit_day || this.baseInfoForm.buy_limit_day === 0) {
        this.defaultChecked = '1'
        this.endTimes.day = this.baseInfoForm.buy_limit_day
      } else {
        this.defaultChecked = '2'
        this.endTimes.hour = this.baseInfoForm.buy_limit_hour
      }

      // 回显活动详情
      this.baseInfoForm.info = this.baseInfoForm.intro;

      // 回显票种
      this.baseInfoForm.ticket_query_vos = this.baseInfoForm.ticket_vos;
      this.ticketTypeList = [];
      this.baseInfoForm.ticket_query_vos.forEach(
        (ticketTypeItem, ticketTypeIndex) => {
          var ticketTypeJson = {};
          ticketTypeJson.ticketPeriodDates = [];
          ticketTypeItem.goods_ticket_date_vos.forEach(
            (ticketDateItem, ticketDateIndex) => {
              var ticketDatesJson = {};
              ticketDatesJson.dates = [
                ticketDateItem.start_date * 1000,
                ticketDateItem.end_date * 1000,
              ];
              ticketDatesJson.count =
                ticketDateItem.goods_ticket_datetimes[0].tpeople_num;
              if (ticketDateItem.ticket_num === 0) {
                var weekArr = ticketDateItem.ticket_weekly.split("|");
                ticketDatesJson.dayList = [
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                ];
                ticketDatesJson.dayList.forEach((dayListItem, dayListindex) => {
                  weekArr.forEach((weekArritem) => {
                    if (dayListindex === Number(weekArritem) - 1) {
                      ticketDatesJson.dayList[dayListindex] = true;
                    }
                  });
                });
              } else {
                ticketDatesJson.ticketPeriodTimes = [];
                ticketDateItem.goods_ticket_datetimes.forEach(
                  (ticketTimeItem, ticketTimeIndex) => {
                    var ticketTimeJson = {};
                    ticketTimeJson.ticketHourLow = ticketTimeItem.time_start.split(
                      ":"
                    )[0];
                    ticketTimeJson.ticketMinLow = ticketTimeItem.time_start.split(
                      ":"
                    )[1];
                    ticketTimeJson.ticketHourHigh = ticketTimeItem.time_end.split(
                      ":"
                    )[0];
                    ticketTimeJson.ticketMinHigh = ticketTimeItem.time_end.split(
                      ":"
                    )[1];
                    ticketDatesJson.ticketPeriodTimes.push(ticketTimeJson);
                  }
                );
              }
              ticketTypeJson.ticketPeriodDates.push(ticketDatesJson);
            }
          );
          this.ticketTypeList.push(ticketTypeJson);
        }
      );

      // 导师回显

      this.baseInfoForm.teacher_ids = [];
      this.baseInfoForm.teachers.forEach((item) => {
        this.baseInfoForm.teacher_ids.push(item.id);
      });
      this.activityTutorList = this.baseInfoForm.teachers;
    },
  },
};
</script>

<style type='text/scss' lang='scss' scoped>
/*编辑基本信息*/
.el-form {
  padding-bottom: 80px;
  .el-form-item {
    width: 100%;
    text-align: left;
  }
}

/*平铺 */
div.base-info-item > div {
  margin-left: 5%;
}
div.base-info-item {
  h4 {
    margin-bottom: 10px;
    padding: 0 10px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    font-weight: bold;
    color: #333;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
  }

  /**  审核信息-拒绝原因 */
  .auth-info {
    color: red;
  }

  .el-form-item {
    width: 30%;
    min-width: 300px;
  }
  .goods-name-width {
    width: 50%;
    min-width: 300px;
  }
  .el-form-item__content {
    margin-left: 120px;
    text-align: left;
  }
  p.goods-group-manager {
    padding-left: 7.5%;
    text-align: left;
    color: #999;
    font-size: 13px;
  }

  /*teatarea*/
  /deep/ .el-textarea {
    width: 150%;
  }
  .seo-text {
    width: 150%;
  }
}

/**  底部步骤 */
.footer {
  width: calc(100vw - 220px);
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 9999;
}

/* 详细地址 */
.addressBox {
  width: 600px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
}

/* 添加出行人标签* / /* 退款金额设置盒子 */
.travelerTagBox,
.refundPriceBox {
  width: 600px;
  border: 2px dashed rgba(221, 221, 221, 1);
  border-radius: 8px;
  padding: 24px 0;
  margin-top: 10px;
}

/* 标签盒子 */
.tagBox {
  display: flex;
  flex-wrap: wrap;
  width: 460px;
  .tag {
    margin-right: 10px;
    margin-bottom: 10px;
    background: #008080;
    color: #fff;
    cursor: pointer;
  }
  .tagNo {
    margin-right: 10px;
    margin-bottom: 10px;
    color: #999;
    border: 1px solid #aaa;
    background: #fff;
    cursor: pointer;
  }
  .tagYes {
    color: #008080;
    border: 1px solid #008080;
    cursor: pointer;
  }
}

/* 图片盒子 */
.activityImgBox {
  width: 660px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  .imgBox {
    width: 110px;
    height: 150px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    position: relative;
    .photo {
      width: 110px;
      height: 110px;
      background: #fff;
    }
  }
}

/* 未上传显示文字 */
.activityUploadBox {
  width: 110px;
  height: 110px;
  border: 1px dashed rgba(221, 221, 221, 1);
  border-radius: 4px;
  background: rgba(247, 250, 250, 1);
  div {
    font-size: 14px;
    line-height: 110px;
    text-align: center;
    color: #999;
  }
}

/* 视频盒子 */
.activityVideoBox {
  width: 600px;
  background: rgba(246, 246, 246, 1);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: flex-end;
}

/* 说明文字 */
.explain {
  font-size: 14px;
  line-height: 20px;
  color: #888;
  margin-left: 10px;
}

/* 视频显示区域 */
.activityUploadVideoBox {
  width: 110px;
  height: 110px;
  video {
    width: 100%;
    height: 100%;
  }
}

/* 票种设置盒子 */
.ticketTypeBox {
  margin-bottom: 30px;
  .ticketTypeTopBox {
    width: 640px;
    background: rgba(255, 255, 255, 1);
    border: 1px dashed rgba(0, 128, 128, 1);
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;
    padding: 18px;
    .ticketTypeTopTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        div {
          width: 6px;
          height: 6px;
          background: rgba(0, 128, 128, 1);
          border-radius: 50%;
        }
        span {
          display: block;
          font-size: 18px;
          line-height: 18px;
          font-weight: bold;
          color: rgba(0, 128, 128, 1);
          margin-left: 5px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .copyButton {
          font-size: 14px;
          line-height: 14px;
          color: rgba(52, 113, 205, 1);
          height: 14px;
          padding: 0;
        }
        .delButton {
          font-size: 14px;
          line-height: 14px;
          color: rgba(254, 85, 88, 1);
          height: 14px;
          padding: 0;
        }
      }
    }
    .ticketTypeIpt {
      margin-top: 20px;
      /deep/ .el-input__inner {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #ddd;
        padding-left: 0;
        font-size: 15px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      .name {
        font-size: 14px;
        color: #666;
      }
      .inpBox {
        display: flex;
        .inp {
          width: 45px;
          /deep/ .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #ddd;
            padding-left: 0;
            font-size: 15px;
            color: #222;
          }
        }
        div {
          font-size: 16px;
          color: #222;
        }
      }
      .selectBox {
        width: 80px;
        /deep/ .el-input__inner {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #ddd;
          padding-left: 0;
          font-size: 15px;
          color: #222;
        }
      }
    }
  }
  .ticketTypeBottomBox {
    width: 640px;
    background: rgba(255, 255, 255, 1);
    border: 1px dashed #ddd;
    border-radius: 0px 0px 8px 8px;
    padding: 20px;
    .ticketTypeBottom {
      border-top: 1px solid #ddd;
      padding-top: 20px;
      margin-top: 20px;
      .titleBox {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          margin-right: 10px;
        }
        div {
          font-size: 16px;
          line-height: 16px;
          font-weight: bold;
          color: rgba(0, 128, 128, 1);
        }
      }
      .dateAndTimeBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        .formItem {
          margin-bottom: 0;
          /deep/ .el-form-item__label {
            width: 100px !important;
            padding: 0;
            text-align: left;
          }
        }
        .timeBox {
          display: flex;
          width: 300px;
          .colon {
            padding: 0 5px;
          }
          .to {
            color: #999;
            padding: 0 5px;
          }
        }
      }
      .dayBox {
        margin-top: 30px;
        .tagBox {
          display: flex;
          .tag {
            color: #999;
            border: 1px solid #aaa;
            background: #fff;
            margin-right: 10px;
          }
          .act {
            color: #008080;
            border: 1px solid #008080;
          }
        }
        .formItem {
          margin-bottom: 0;
          /deep/ .el-form-item__label {
            width: 90px !important;
            padding: 0;
            text-align: left;
          }
        }
      }
    }
    .noBorder {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

/* 预定截止时间盒子 */
.endTimeBox {
  display: flex;
  flex-direction: column;
  .endTimeRadio {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .endTimeBox1 {
    display: flex;
    width: 600px;
    .select {
      width: 130px;
    }
    .inpBox {
      margin-left: 35px;
      display: flex;
      align-items: center;
      .endTimeIpt {
        width: 60px;
        /deep/ .el-input__inner {
          width: 60px;
        }
      }
    }
  }
}

/* 退款金额设置盒子 */
.refundPriceBox {
  padding: 24px 24px 0;
  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left {
      display: flex;
      width: 560px;
      .text {
        font-size: 14px;
        color: #666;
        text-align: center;
      }
      .soldCountInp {
        width: 60px !important;
        /deep/ .el-input__inner {
          width: 60px !important;
          color: #606266;
        }
      }
    }
  }
}

/* 活动详情盒子 */
.activityDetails {
  width: 700px;
  .buttonBox {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
    }
  }
  .info {
    font-size: 14px;
    color: #888;
    margin-top: 20px;
    span {
      font-size: 14px;
      color: #fe5558;
    }
  }
}

/* 活动亮点盒子 */ /* 活动行程盒子 */
.characteristicBox,
.tripBox {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin-bottom: 10px;
}

/* 活动行程盒子 */
.tripBox {
  .tripContentBox {
    border: 1px dashed rgba(221, 221, 221, 1);
    border-radius: 8px;
    padding: 20px 10px;
    display: flex;
    width: 560px;
    .left {
      width: 30px;
      line-height: 74px;
      text-align: center;
      background: #008080;
      color: #fff;
      margin-right: 10px;
      border-radius: 8px;
    }
    .right {
      flex: 1;
      .inp {
        /deep/ .el-input__inner {
          color: #606266;
        }
        /deep/ .el-textarea__inner {
          color: #606266;
        }
      }
    }
  }
}

.width {
  width: 400px;
}

// 修改默认样式
.ipt-default,
.el-cascader,
.el-select,
.el-textarea,
.ticketTypeIpt,
.el-date-editor,
.inp,
.endTimeIpt {
  /deep/ .el-input__inner {
    color: #606266;
  }
  /deep/ .el-textarea__inner {
    color: #606266;
  }
  /deep/ .el-range-input {
    color: #606266;
  }
}
/deep/ .el-radio {
  .el-radio__input {
    .el-radio__inner {
      background-color: #fff;
    }
  }
  span.el-radio__label {
    color: #606266;
  }
  .is-checked {
    .el-radio__inner {
      border-color: #409eff;
      background: #409eff;
    }
    .el-radio__inner::after {
      background-color: #fff;
    }
  }
  .is-checked + .el-radio__label {
    color: #409eff;
  }
}
</style>
<style type='text/scss' lang='scss'>
.el-form-item__label {
  word-break: break-all;
}

.urgentBox {
  .el-form-item__label {
    width: 140px !important;
  }
}
</style>
